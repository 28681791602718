import React from "react";
import style from "./index.module.css";

function Info({ icon, children, item, setMessage, setAlertActive, link }) {
  return (
    <div
      className={style.info}
      onClick={() => {
        if(item !== "Twitter"){
          navigator.clipboard.writeText(children).then(() => {
            setMessage(`${item} copied`);
            setAlertActive(true);
          });
        } else {
          setMessage(`Redirecting to X (Twitter)`);
          setAlertActive(true);
          window.open(link, "_blank");
        }
      }}
    >
      {icon}
      <div className={style.value_container}>
        <div className={style.value}>
          <p>{children}</p>
          <p>{children}</p>
        </div>
      </div>
    </div>
  );
}

export default Info;
