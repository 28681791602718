import React, { useState, useEffect } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import whotImage from "../../assets/whot.png";
import cryptoImage from "../../assets/crypto.png";
import Project from "../Project/Project";
import Button from "../Button/Button";
import Currently from "../Currently/Currently";
import ProjectSkeleton from "../ProjectSkeleton/ProjectSkeleton";
import { AxiosCont } from "../../constants/AxiosCont";

function Projects() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const results = await AxiosCont.get("/get-project");
        const result = results.data.data;
        console.log({ Projects: result });
  
        const parsedProjects = result.map((project) => {
          const parseField = (field) => {
            if (typeof field === "string") {
              try {
                return JSON.parse(field);
              } catch {
                return field.includes(",") ? field.split(",") : [field];
              }
            } else if (Array.isArray(field)) {
              return field;
            }
            return [];
          };
  
          return {
            ...project,
            roles: parseField(project.roles),
            technologies: parseField(project.technologies_json),
          };
        });

        localStorage.setItem("chimdi-projects", JSON.stringify(parsedProjects.slice(0, 5)));
        setProjects(parsedProjects);
      } catch (error) {
        console.error("Error fetching projects:", error);

        const cachedProjects = localStorage.getItem("chimdi-projects");
        if (cachedProjects) {
          const data = JSON.parse(cachedProjects);
          if(data.length) setProjects(data);
        }

      } finally {
        setLoading(false);
      }
    };
  
    fetchProjects();
  }, []);
  
  

  return (
    <section className="projects" id="projects">
      <SectionHeading>MY WΘRK</SectionHeading>
      <p className="talk">

      </p>
      <section className="projects-container">
        {loading ? (
          <>
            <ProjectSkeleton />
            <ProjectSkeleton />
          </>
        ) : (
          projects.map((project, index) => (
            <Project project={project} key={project.title} index={index} />
          ))
        )}
      </section>
      {/* <Button className="btn" to={"/archive"} isLink>
        SEE MORE
      </Button> */}
      {/* <Currently className="currently" /> */}
    </section>
  );
}

export default Projects;
